import React from "react"
import {graphql} from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ReferencesPage = ({data}) => { 
    const ref ={
        'costa-sud': {city:'Agadir', address:'Café & Restaurant'},
        'greens': {city:'Agadir', address:'Café Restaurant'},
        'oasis': {city:'Agadir', address:'Hôtel & spa'},
        'inrh': {city:'Agadir', address:'Institut National de Recherche Halieutique'},
        'sofitel': {city:'Agadir/Marrakech', address:'Hôtels de luxes'},
        'california': {city:'Bouskoura', address:'Groupement des propriétaires'},
        'elite': {city:'Agadir', address:'Centre de sport & Fitness'},
        'beach-club': {city:'Agadir', address:'Hôtel'},
        'elite': {city:'Bouskoura', address:'Groupement des propriétaires'},
        'michelin': {city:'Agadir', address:'Fabricant de pneumatiques français'},
        'macdo': {city:'Agadir', address:'Restaurant'},
        'atlantic-palace': {city:'Agadir', address:'Hôtel'},
        'taghazout-bay': {city:'Agadir', address:'Station éco-touristique'},
        'opasta': {city:'Agadir', address:'Restaurant'},
        'almogar': {city:'Agadir', address:'Hôtel'},
        'la-coupole': {city:'Agadir', address:'Restaurant'},
        'pharma': {city:'Marrakech', address:'Pharmacie'},
        'host': {city:'Agadir', address:'HOST Coffee Food'},
        'taiba': {city:'Agadir', address:'Café & Délices'},
        'caramel': {city:'Agadir', address:'Restaurant Boulangerie Café'},
        'continental': {city:'Agadir', address:'Atelier d’usinage'},
        'vip-wear': {city:'Agadir', address:'Magasin de vêtements'},
        'fine-fish': {city:'Agadir', address:'Restaurant de spécialités à base de poisson-pêcheur'},
        'caprice': {city:'Agadir', address:'Boulangerie et Café'},
        'decameron': {city:'Agadir', address:'Hôtel'},
        'megal': {city:'Agadir', address:'Spécialiste Aluminium'},
        'tacos-de-lyon': {city:'Agadir', address:'Restaurant'},
        'clinique-dentaire-agadir': {city:'Agadir', address:'Clinique Dentaire'},
        'home-cafe': {city:'Agadir', address:'Restaurant'},
        'centre-dentaire-ezzine': {city:'Agadir', address:'Dentiste'},
        'costeau-bois': {city:'Agadir', address:'Menuiserie de Bois'},
        'juice': {city:'Agadir', address:'Restaurant'},
        'chiringuito': {city:'Agadir', address:'Restaurant'},
        'dailys': {city:'Agadir', address:'Restaurant'},
        'mra': {city:'Marrakech', address:'Parc & Jardin'},
        'azyam': {city:'Agadir', address:'Restaurant de spécialités à base de poisson-pêcheur'},
        'tiriglass-maroc': {city:'Agadir', address:'Vitrage & Aluminium'},
        'tafarnout': {city:'Agadir', address:'Café & Boulangerie'}, 
    }
        
    
  return(
  <Layout>
    <SEO title="CORSAF Flayers" />
    <div class="grid grid-cols-1 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
      <h1 class="text-center text-2xl mt-5 font-bold">Ils Nous Font Confiance</h1>
      <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5 mb-5"></div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
         
        { data.ref.edges.map(({ node }) =>
          <div class="ref-logo rounded-full relative">
              <div class="absolute">
                <Img className="rounded-full image cursor-pointer absolute z-0" fluid={node.childImageSharp.logo} />
              </div>
              <div class="details absolute cursor-pointer rounded-full z-10 flex items-center flex-col text-center justify-center text-white bg-gray-800">
                <p class="mx-0 mt-0 mb-2 font-bold">{ ref[node.name]? ref[node.name].city:''}</p>
                <p class="mx-0 mt-0 mb-2 border-b-2 mx-auto border-gray-500 h-1 w-2/3"></p>
                <p class="mx-0 my-0">{ref[node.name]? ref[node.name].address:''}</p>
              </div>
                
          </div>
        )}
         
    </div>
    <div class="full mt-10 bg-gray-300">
        <div class="grid grid-cols-1 mt-8  md:mx-40 md:my-5 mx-3">
            <h1 class="text-center text-2xl mt-5 font-bold">Nos Partenaires</h1>
            <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5 mb-5"></div>
            <div class="partenaires grid md:grid-cols-5 ">
              { data.partner.edges.map(({ node, index }) =>
                  <div class="logo w-40">
                      <Img class="rounded-full cursor-pointer" fluid={node.childImageSharp.logo} />
                      <p>{ref[parseInt(node.name.city)]}</p>
                  </div>
              )}
            </div>
        </div>
    </div>
    </Layout>
)
}
export const query = graphql`
query ReferencesLogo {
  ref:allFile (filter:{relativeDirectory: {eq: "img/realisations/ref"}}){
    edges {
      node {
        name
        childImageSharp {
            logo: fluid(maxWidth: 100) {
                ...GatsbyImageSharpFluid
              }
        }
      }
    }
  }
  partner:allFile (filter:{relativeDirectory: {eq: "img/realisations/partenaires"}}){
    edges {
      node {
        name
        childImageSharp {
            logo: fluid(maxWidth: 100) {
                ...GatsbyImageSharpFluid
              }
        }
      }
    }
  }
}
`
export default ReferencesPage